<template>
  <v-dialog v-model="modalData.dialog" max-width="650px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        New vehicle
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <span
              style="color: white"
              :style="{ backgroundColor: formModel.color }"
            >
              &nbsp;&nbsp;{{ formModel.name }} -
              {{ formModel.license_number }} &nbsp;</span
            >
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.companySettings.department"
                label="Status"
                item-text="value"
                item-value="key"
              />
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model="formModel.name"
                  label="Name"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model="formModel.license_number"
                  label="License number"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-select
                  v-model="formModel.site_id"
                  :items="siteCollection"
                  label="Site"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                  :rules="requiredRules"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-color-picker
                  v-model="formModel.color"
                  dot-size="25"
                  hide-canvas
                  hide-inputs
                  hide-mode-switch
                  hide-sliders
                  show-swatches
                  swatches-max-height="200"
                  label="Color"
                ></v-color-picker>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-select
                  v-model="formModel.user_group_id"
                  :items="userGroupCollection"
                  label="User group"
                  item-text="groupName"
                  item-value="id"
                  :id="dynamicID"
                  :rules="requiredRules"
                />

                <v-text-field
                  v-model="formModel.max_weight"
                  label="Max weight"
                  :rules="requiredRules"
                ></v-text-field>
                <label for="datepicker-full-width">Mot validity date</label
                ><b-form-datepicker
                  id="datepicker-expiration_date"
                  v-model="formModel.mot_validity_date"
                  :min="new Date(Date.now())"
                  calendar-width="100%"
                  locale="en"
                  start-weekday="1"
                  class="mb-2"
                  style="max-width: 260px"
                  required
                  @context="messages['mot_validity_date'] = ''"
                ></b-form-datepicker>
                <small v-if="messages['mot_validity_date']" style="color: red">
                  {{ messages["mot_validity_date"] }}
                </small>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="formModel.comment"
                  rows="5"
                  label="Comment"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ENDPOINT, PERMISSION_TO } from "./Vehicles";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";

const TRANSLATED_ATRIBUTES = [];

export const initialFormData = () => ({
  id: null,
  license_number: "",
  site_id: null,
  user_group_id: null,
  name: "",
  mot_validity_date: null,
  max_weight: null,
  status: 1,
  color: "#fcc",

  comment: "",
  translations: [],
});

export default {
  name: "VehicleModalForm",
  props: ["modalData", "permissions"],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,

      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length < 15 || "Name must be max 16 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  computed: {
    ...mapGetters(["userGroupCollection", "siteCollection", "statuses"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id ? "Edit vehicle" : "New vehicle";
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value.editedId) {
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchUserGroups", "fetchSite"]),

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        // this.modalData.loading = true;

        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            if (
              model.translations[langCode] &&
              model.translations[langCode].name != ""
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
  },

  async mounted() {
    this.setTranslatedAttributes();
    this.fetchUserGroups();
    this.fetchSite();
  },
};
</script>
